// Получение элементов DOM
const formSelector = document.getElementById('form-selector');
const addIncomePaymentForm = document.getElementById('add-income-payment-form');
const addRefundPaymentForm = document.getElementById('add-refund-payment-form');
const formsContainer = document.getElementById('forms');
const mainSpinner = document.querySelector('.spinner-container');
const paymentSlip = document.getElementById('payment-slip');


// Переменные, связанные с формами и данными
let currentForm;
let currentBookingData;
let uploadedFilesCounter = 0;
let isFileUploading = false;
let isFileUploaded = false;

// Флаг, показывающий добавляется ли прямо сейчас какой-то платеж (чтобы случайно дважды не отправляли пользователи запрос)
let isPaymentAdding = false;

// Событие, отвечающее за анимацию кнопки выбора формы// Переключатель на первую форму
formSelector.addEventListener('click', () => {
    if (formSelector.classList.contains('main-menu-centered')) {
        formSelector.classList.remove('main-menu-centered');
    }
});

// Переключатель на инструкцию (форма паспорта) при клике на 'Получить инструкцию'
const getInstructionBtn = document.getElementById('get-instruction-btn');

// Добавляем обработчик события "click" к кнопке
getInstructionBtn.addEventListener('click', () => {
    // Получаем bookingId из currentBookingData
    const bookingId = currentBookingData.bookingId;

    // Строим URL, используя bookingId
    // Перенаправляем пользователя на новый URL
    // TODO:: Заменить на новую ссылку, пока ещё инструкция в разработке
    //window.location.href = `https://checkin-axismoscow.web.app/${bookingId}/admin_instruction/45661ec20242ac120002`;
});
//

// Устанавливает ограничения на выбор даты оплаты (основываясь на текущей дате)
document.addEventListener('DOMContentLoaded', () => {
    const paymentDateInput = document.getElementById('payment-date');

    const today = new Date();
    const minDate = new Date();
    minDate.setDate(today.getDate() - 3); // 3 дня назад

    paymentDateInput.min = formatDate(minDate);
    paymentDateInput.max = formatDate(today);

    function formatDate(date) {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // Январь это 0!
        const yyyy = date.getFullYear();

        return yyyy + '-' + mm + '-' + dd;
    }
});

// Загрузка данных из localStorage при инициализации страницы
document.addEventListener('DOMContentLoaded', () => {
    uploadedFilesCounter = +localStorage.getItem('uploadedFilesCounter') || 0;
});

// Предотвращение стандартного поведения формы при отправке
formsContainer.addEventListener('submit',   (event) => {
    event.preventDefault();
});

// Обработчик событий для загрузки файла платежного поручения
paymentSlip.addEventListener('change', async ({target}) => {
    // Проверка условий для загрузки
    if (!currentBookingData || isFileUploading) {
        paymentSlip.classList.add('hidden');
        return;
    }
    isFileUploading = true;
    paymentSlip.classList.add('hidden');
    paymentSlip.parentNode.querySelector('.field-spinner').classList.remove('hidden');
    try {
        // Чтение и сохранение файла
        console.log('run paymentSlip')
        const file = target.files[0];
        const ext = file.name.split(".").pop();
        const fileContent = await readFileAsBase64(file);
        console.log('after fileContent');
        const fileName = `${currentBookingData.guestName}-add-in-dds-${uploadedFilesCounter}.${ext}`;
        const mimeType = file.type;
        const filesData = [
            {
                content: fileContent,
                name: fileName,
                mimeType
            }
        ];
        console.log(filesData);
        const folderKey = `${currentBookingData.guestName}-${currentBookingData.bookingId}`; // Ключ по которому называются папки с бронированиями
        await saveFiles(filesData, currentBookingData.hotelName, folderKey);
        uploadedFilesCounter += 1;
        localStorage.setItem('uploadedFilesCounter', uploadedFilesCounter);
    } catch(error) {
        // Обработка ошибок
        showErrorPopup(`Ошибка при загрузке файла`, error.message, currentBookingData.bookingId);
        isFileUploading = false;
        paymentSlip.classList.remove('hidden');
        paymentSlip.parentNode.querySelector('.field-spinner').classList.add('hidden');
    }
    isFileUploading = false;
    paymentSlip.classList.remove('hidden');
    paymentSlip.parentNode.querySelector('.field-spinner').classList.add('hidden');
    isFileUploaded = true;
});


// Обработчик события нажатия на кнопку отправки в первой форме
addIncomePaymentForm.querySelector('.submit-btn').addEventListener('click', async ({target}) => {
    const form = target.closest('form');
    if (!currentBookingData) {
        alert("Пожалуйста, подождите пока бронирование подгрузится");
        return;
    }
    if (isPaymentAdding) {
        console.warn("Пожалуйста, подождите пока предыдущий платеж добавится");
        return;
    }
    const {bookingId, id, hotelName, guestName} = currentBookingData;

    const paymentType = form.querySelector('.payment-type').value;
    const paymentAmount = form.querySelector('.payment-amount').value;

    const [bnovoAccount, paymentSourceName] = form.querySelector('#account').value.split(';');
    const paymentDate = form.querySelector('.payment-date').value;
    const paymentCreator = document.getElementById('user-name').textContent;
    const userEmail = window.user.email;
    const userUid = window.user.uid;
    if (!hotelName) {
        alert("Пожалуйста, заполните поле 'Название отеля'.");
        return;
    } else if (!bnovoAccount) {
        alert("Пожалуйста, выберите источник оплаты Bnovo.");
        return;
    } else if (!paymentSourceName) {
        alert("Пожалуйста, выберите источник оплаты как в ДДС.");
        return;
    } else if (!paymentDate) {
        alert("Пожалуйста, выберите дату оплаты.");
        return;
    } else if (!paymentType) {
        alert("Пожалуйста, выберите тип оплаты.");
        return;
    }
    mainSpinner.classList.remove('hidden');
    console.log(paymentType, paymentAmount, bnovoAccount, paymentSourceName, bookingId, id, hotelName, guestName, paymentDate, paymentCreator);

    // В зависимости от типа оплаты выполняем разные действия
    if (paymentType === 'deposit+accommodation') {
        // Два платежа в bnovo и два в ДДС упадет

        const paymentLivingAmount = form.querySelector('.accommodation-amount').value;
        const paymentDepositAmount = form.querySelector('.deposit-amount').value;

        if (!paymentLivingAmount) {
            alert("Пожалуйста, заполните поле 'Сумма оплаты за проживание'.");
            return;
        } else if (!paymentDepositAmount) {
            alert("Пожалуйста, заполните поле 'Сумма залога'.");
            return;
        }

        isPaymentAdding = true;
        // Создаем платеж за проживание
        const dataAccommodation = await addPayment('accommodation', paymentLivingAmount,
            bnovoAccount,
            paymentSourceName,
            bookingId,
            id,
            hotelName,
            guestName,
            userEmail,
            userUid,
            paymentDate, paymentCreator);

        console.log(dataAccommodation);

        if (dataAccommodation.result === 'error') {
            showErrorPopup(dataAccommodation.message, bookingId);
            mainSpinner.classList.add('hidden');
            isPaymentAdding = false;
            return;
        }
        const dataDeposit = await addPayment('deposit', paymentDepositAmount,
            bnovoAccount,
            paymentSourceName,
            bookingId,
            id,
            hotelName,
            guestName,
            userEmail,
            userUid,
            paymentDate, paymentCreator);
        console.log(dataDeposit);
        isPaymentAdding = false;
        if (dataDeposit.result === 'error') {
            showErrorPopup(dataDeposit.message, bookingId);
            mainSpinner.classList.add('hidden');
            return;
        }
    } else if (paymentType === 'accommodation' || paymentType === 'deposit') {
        if (!paymentAmount) {
            alert("Пожалуйста, заполните поле 'Сумма оплаты'.");
            return;
        }
        isPaymentAdding = true;
        const data = await addPayment(paymentType, paymentAmount,
            bnovoAccount,
            paymentSourceName,
            bookingId,
            id,
            hotelName,
            guestName,
            userEmail,
            userUid,
            paymentDate, paymentCreator);
        console.log(data);

        isPaymentAdding = false;
        if (data.result === 'error') {
            showErrorPopup(data.message, bookingId);
            mainSpinner.classList.add('hidden');
            return;
        }
    } else if (paymentType === 'additional-service') {
        const selectedServiceId = window.paymentAdditionalServiceSelect.getSelected()[0];
        if (!selectedServiceId) {
            alert("Пожалуйста, выберите доп.услугу");
            return;
        }
        if (!paymentAmount) {
            alert("Пожалуйста, заполните поле 'Сумма оплаты'.");
            return;
        }
        isPaymentAdding = true;
        const data = await addPayment(paymentType, paymentAmount,
            bnovoAccount,
            paymentSourceName,
            bookingId,
            id,
            hotelName,
            guestName,
            userEmail,
            userUid,
            paymentDate, paymentCreator, {...currentBookingData.additionalServices[selectedServiceId], service_id: selectedServiceId});
        console.log(data);

        isPaymentAdding = false;
        if (data.result === 'error') {
            showErrorPopup(data.message, bookingId);
            mainSpinner.classList.add('hidden');
            return;
        }
    }

    // Выводим уведомление об успешной оплате
    if (paymentType.includes('deposit')) {
        showSuccessPopup(`Запрос отправлен. Оплата поступит в Bnovo и ДДС в течение 5 минут и будет обновлен ЧЛ по заездам.`);
    } else {
        showSuccessPopup(`Запрос отправлен. Оплата поступит в Bnovo и ДДС в течение 5 минут`);
    }
    mainSpinner.classList.add('hidden');
});

// Слушатель событий для кнопки отправки в форме возврата
addRefundPaymentForm.querySelector('.submit-btn').addEventListener('click', async ({target}) => {
    const form = target.closest('form');
    if (!currentBookingData) {
        alert("Пожалуйста, подождите, пока загружается бронирование");
        return;
    }
    if (isPaymentAdding) {
        alert("Пожалуйста, подождите, пока добавляется предыдущий платеж");
        return;
    }
    const { bookingId, id, hotelId, hotelName, guestName, room, arrival, departure } = currentBookingData;

    const paymentAmount = form.querySelector('.payment-amount').value;
    const [bnovoAccount, paymentSourceName] = form.querySelector('#account-2').value.split(';');
    const paymentDate = new Date().toISOString();
    const paymentCreator = document.getElementById('user-name').textContent;
    const phoneOrCard = form.querySelector('#phone-or-card').value;
    const bank = form.querySelector('#bank').value;
    const fio = form.querySelector('#fio').value;
    const paymentWay = window.refundPaymentWaySelect.getSelected()[0];
    const paymentType = window.refundArticleSelect.getSelected()[0].includes('проживание') ? 'accommodation' : 'deposit';
    const refundAccount = window.refundAccountSelect.getSelected()[0];
    const unicId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const cancelReason = form.querySelector('#cancel-reason').value;
    const userEmail = window.user.email;
    const userUid = window.user.uid;
    let comment = '';
    if (!hotelName) {
          alert("Пожалуйста, заполните поле 'Название отеля'.");
          return;
      } else if (!bnovoAccount) {
          alert("Пожалуйста, выберите аккаунт Bnovo.");
          return;
      } else if (!paymentSourceName) {
          alert("Пожалуйста, выберите аккаунт DDS.");
          return;
      } else if (!paymentDate) {
          alert("Пожалуйста, выберите дату платежа.");
          return;
      } else if (!paymentAmount) {
          alert("Пожалуйста, выберите сумму возврата");
          return;
      } else if (!paymentWay) {
        alert("Пожалуйста, выберите способ возврата");
        return;
      }
        else if (!cancelReason) {
          alert("Пожалуйста, заполните поле 'Причина отмены'");
          return;
      }
    // Проверяем, что заполнены поля, только если выбран способ возврата "Внести данные"
    if (refundAccount === 'Внести данные') {
       if (!phoneOrCard) {
        alert("Пожалуйста, заполните поле 'Телефон / Номер карты'");
        return;
      } else if (!bank) {
        alert("Пожалуйста, заполните поле 'Банк'");
        return;
      } else if (!fio) {
        alert("Пожалуйста, заполните поле 'ФИО'");
        return;
      }
      comment = `Телефон / Номер карты: ${phoneOrCard}, Банк: ${bank}, ФИО: ${fio}`;
    }
    mainSpinner.classList.remove('hidden');

    if (!paymentAmount) {
        alert("Пожалуйста, заполните поле 'Сумма возврата'.");
        return;
    }

    isPaymentAdding = true;
    // Делаем вызов на скрипт который зарегистрирует операцию в YDB, дабы она появилась в списке "Мои операции" со статусом in progress
    // Ждем пока операция создатся
    console.log('call the createInProgressRefundOperation');
    await createInProgressRefundOperation(
      unicId,
      'refund',
      id,
      bookingId,
      paymentType,
      guestName,
      userEmail,
      userUid,
      hotelName,
      paymentDate,
      paymentSourceName,
      userEmail,
      paymentAmount,
      '', // не требуется для возвратов
      comment,
      cancelReason,
      paymentWay,
    );
    const data = await addRefundPayment(
        hotelName,
        userEmail,
        userUid,
        paymentType,
        paymentCreator,
        paymentSourceName,
        paymentAmount,
        bookingId,
        id,
        arrival,
        departure,
        unicId,
        paymentDate,
        room,
        paymentWay,
        guestName,
        comment,
        cancelReason,
        hotelId,
    );
    console.log(data);

    isPaymentAdding = false;
    if (data.result === 'error') {
        showErrorPopup(data.message, bookingId);
        mainSpinner.classList.add('hidden');
        return;
    }

    // Отображение уведомления о успешном возврате
    showSuccessPopup(`Запрос отправлен. Возврат будет добавлен в ДДС течение 5 минут (проверьте ДДС)`);
    mainSpinner.classList.add('hidden');
})
// Обработчик события изменения контейнера форм. Проверяем условия для отображения слипа оплаты
formsContainer.addEventListener('change', () => {
    // Если бронь подгружена и выбрана дата оплаты, значит можно подгружать слип оплаты
    if (currentBookingData && forms.querySelector('.payment-date').value) {
        paymentSlip.classList.remove('hidden'); // Показываем возможность загрузки слипа оплаты
    }
})

